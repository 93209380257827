<template>
  <router-view :appColor="appColor"></router-view>
</template>

<script>
export default {
  props: {
    appColor: {
      type: String,
    },
  },
};
</script>
